<script lang="ts" setup>
import { debounce } from "perfect-debounce";
import type { FormState } from "~/models/forms";

const route = useRoute();
const localePath = useLocalePath();
const { t } = useI18n();
const { trackCustomEvent } = useGtmTracking();

const { setParamsAndExecute, setParamsAndNavigate } = await useCatalog();
const searchTerm = useSearchTerm();

const searchTermInput = ref(null);
const searchSuggestionsContainer = ref(null);
const isOpenSearchSuggestions = ref(false);
onClickOutside(searchSuggestionsContainer, _ => isOpenSearchSuggestions.value = false);

const searchSuggestionState = ref<FormState>("idle");
const searchSuggestionList = ref<Array<any>>([]);
const searchSuggestionSelectedIndex = ref(-1);
const queryLength = computed(() => searchTerm.value.length);

async function fetchData() {
  try {
    searchSuggestionList.value = [];
    isOpenSearchSuggestions.value = false;

    if (queryLength.value < 3) {
      return;
    }

    searchSuggestionState.value = "pending";

    const data = await $fetch("/api/ecom/search-suggestion", {
      query: { term: searchTerm.value },
    });

    if (data && data.length > 0) {
      searchSuggestionList.value = data.map((i) => {
        return {
          id: i.id,
          label: i.name,
          select: () => {
            isOpenSearchSuggestions.value = false;
            trackCustomEvent("search-suggestion-select");
            navigateTo(localePath(i.detailUrl));
          },
        };
      });

      searchSuggestionSelectedIndex.value = -1;
    }

    isOpenSearchSuggestions.value = true;
    trackCustomEvent("search-suggestion-fetch");
  }
  catch (error) {
    console.error(error);
    useFailToast(
      t("common.title.error"),
      t("search-suggestions.message.error"),
    );
  }
  finally {
    searchSuggestionState.value = "idle";
  }
}

const debouncedFetchData = debounce(fetchData, 200);

watch(() => searchTerm.value, () => {
  if (searchTerm?.value) {
    debouncedFetchData();
  }
});

function onFocusSearchTerm() {
  if (!isOpenSearchSuggestions.value && searchTerm?.value) {
    fetchData();
  }
}

function onKeySearchTerm(event: KeyboardEvent) {
  switch (event.code) {
    case "ArrowDown":
      event.preventDefault();
      event.stopPropagation();
      searchSuggestionSelectedIndex.value = 0;
      (searchSuggestionsContainer.value! as HTMLElement).focus();
      break;
  }
}

function onKeySearchSuggestions(event: KeyboardEvent) {
  switch (event.code) {
    case "ArrowDown":
      if (searchSuggestionSelectedIndex.value < searchSuggestionList.value.length - 1) {
        searchSuggestionSelectedIndex.value = searchSuggestionSelectedIndex.value + 1;
      }
      break;

    case "ArrowUp":
      if (searchSuggestionSelectedIndex.value === 0) {
        (searchTermInput.value as any).$refs.input.focus();
      }
      else if (searchSuggestionSelectedIndex.value > 0) {
        searchSuggestionSelectedIndex.value = searchSuggestionSelectedIndex.value - 1;
      }
      break;

    case "Enter":
      searchSuggestionList.value[searchSuggestionSelectedIndex.value].select();
      break;
  }
}

async function onSubmit() {
  searchSuggestionList.value = [];

  if (route.path.includes("/catalog") && !route.path.includes("/product")) {
    await setParamsAndExecute({ categoryId: undefined, term: searchTerm.value });
  }
  else {
    await setParamsAndNavigate({ term: searchTerm.value });
  }
}
</script>

<template>
  <div class="relative z-20">
    <form class="w-full" @submit.prevent="onSubmit">
      <UButtonGroup class="w-full">
        <UInput
          ref="searchTermInput"
          v-model="searchTerm"
          tabindex="0" type="search" class="w-full"
          :placeholder="t('search-suggestions.placeholder')"
          :loading="searchSuggestionState === 'pending'" trailing
          aria-autocomplete="both" aria-owns="search-suggestions"
          :aria-expanded="isOpenSearchSuggestions"
          data-1p-ignore
          @focus="onFocusSearchTerm"
          @keydown="onKeySearchTerm"
        />

        <div
          v-show="isOpenSearchSuggestions && searchSuggestionList.length > 0"
          ref="searchSuggestionsContainer"
          tabindex="1"
          class="absolute top-[45px] w-full rounded-md border-1 border-ash-200 bg-white shadow-lg
                    focus-within:ring-1 focus:ring-1 focus-visible:outline-none focus-visible:ring-1 sm:top-5xl"
          @keydown.prevent="onKeySearchSuggestions"
        >
          <ul id="search-suggestions" class="flex flex-col gap-sm p-xs" role="listbox">
            <li
              v-for="(searchSuggestion, idx) in searchSuggestionList"
              :key="searchSuggestion.id"
            >
              <button
                type="button"
                class="w-full cursor-pointer rounded-md p-sm text-left hover:bg-ash-100 focus:outline-none"
                :class="{ 'bg-ash-100': searchSuggestionSelectedIndex === idx }"
                @click="searchSuggestion.select"
              >
                {{ searchSuggestion.label }}
              </button>
            </li>
          </ul>
        </div>

        <UButton type="submit" icon="i-ic-round-search" square />
      </UButtonGroup>
    </form>
  </div>
</template>

<style scoped></style>
