<script lang="ts" setup>
import type { LayoutModelMainNavigationField } from "~/graphql/datocms/generated";

defineProps<{ navItems: LayoutModelMainNavigationField[] }>();

const { resetCatalog } = await useCatalog();
const localePath = useLocalePath();
const route = useRoute();

function getLink(navItem: LayoutModelMainNavigationField) {
  if (navItem.__typename === "CustomLinkRecord") {
    return navItem.optLinkUrl;
  }

  if (navItem.__typename === "PageLinkRecord") {
    return navItem.link.slug;
  }

  return `#unknown-link-type`;
}

async function checkLink(navItem: LayoutModelMainNavigationField) {
  const link = localePath(getLink(navItem)!);

  if (route.meta.catalogSearchType && route.path === link) {
    resetCatalog();
    return;
  }

  await navigateTo(link);
}
</script>

<template>
  <div class="bg-header-main">
    <UContainer class="py-4xl">
      <div class="flex gap-4xl">
        <NuxtLinkLocale to="/">
          <NuxtImg preload src="/logos/vanuxeem.svg" alt="Vanuxeem Logo" title="Vanuxeem" class="h-[60px]" />
        </NuxtLinkLocale>
        <div class="flex flex-auto flex-col gap-xl">
          <nav class="flex flex-wrap gap-x-4xl text-blue">
            <NuxtLinkLocale
              v-for="navItem in navItems"
              :key="navItem.id"
              :to="getLink(navItem)"
              class="pb-md pt-xs font-medium transition-shadow duration-200 hover:shadow-underline-hover"
              :class="{
                'shadow-underline-active': getLink(navItem)?.includes(route.meta?.catalogSearchType),
              }"
              active-class="shadow-underline-active"
              @click.prevent="checkLink(navItem)"
            >
              {{ navItem.text }}
            </NuxtLinkLocale>
          </nav>
          <div class="flex gap-xl">
            <SearchSuggestions class="flex-auto drop-shadow-sm" />
            <CartMini />
          </div>
        </div>
      </div>
    </UContainer>
  </div>
</template>

<style scoped></style>
