<script lang="ts" setup>
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true,
});
</script>

<template>
  <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
    <Head>
      <Link rel="preconnect" href="https://media.helice.cloud" />
      <Link rel="preconnect" href="https://www.datocms-assets.com" />

      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>

      <Link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@fancyapps/ui@5.0/dist/fancybox/fancybox.css" />
    </Head>
    <Body class="flex flex-col">
      <DatoCmsPreviewBanner />
      <HeaderBase />

      <main class="flex-1">
        <slot />
      </main>

      <FooterBase />
    </Body>
  </Html>
</template>

<style></style>
