<script lang="ts" setup>
const data = await useLayout();
</script>

<template>
  <header v-if="data">
    <HeaderStandardBase :data="data" class="hidden md:block" />
    <HeaderMobileBase :data="data" class="md:hidden" />
  </header>
</template>

<style scoped></style>
